/* BlogPost.module.css */

.blogPostContainer {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: center;
  }
  
  .featuredImage {
    width: 100%;
    border-radius: 10px;
  }
  
  .likeButton, .shareButton {
    padding: 10px 15px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .likeButton {
    background: #28a745;
    color: white;
  }
  
  .shareButton {
    background: #007bff;
    color: white;
  }
  