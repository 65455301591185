/* AllBlogPosts.module.css */

.blogContainer {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blogGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .blogCard {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .blogImage {
    width: 100%;
    border-radius: 10px;
  }
  
  .category {
    font-size: 14px;
    color: #007bff;
  }
  
  .excerpt {
    font-size: 16px;
  }
  
  .readMore {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  