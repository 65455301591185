.container {
  width: 100%;
  margin: 0;
  padding: 0;
}
.imageContainer {
  width: 100%;
  position: unset !important;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.moreDataContainer {
  z-index: 1 !important;
  position: relative !important;
}
.moreDataButton {
  margin-top: -5rem;
  margin-right: 2rem;
  width: "40px";
  height: fit-content;
  padding: 8px;
  border:solid;
  background: none;
  border-radius: 0.5rem;
  border-color: var(--primaryColor);
  border-width: 0.1rem;
  color: var(--primaryColor);
  cursor: pointer;
  float: right;
  align-items: flex-end !important;
  justify-content: right !important;
}
.moreDataButton:hover {
  align-items: flex-end;
  background-color: var(--hoverColor);
  transform: 0.75s;
}
.moreDataButton1 {
    margin-top: -5rem;
    margin-right: 2rem;
    width: "40px";
    height: fit-content;
    padding: 8px;
    border:solid;
    background: none;
    border-radius: 0.5rem;
    border-color: #fff;
    border-width: 0.1rem;
    color: #fff;
    cursor: pointer;
    float: right;
    align-items: flex-end !important;
    justify-content: right !important;
  }
  .moreDataButton1:hover {
    align-items: flex-end;
    background-color: var(--primaryColor);
    transform: 0.75s;
  }