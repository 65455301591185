/* General Container */
.productDetailsContainer {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Product Info Section */
.productInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.productImage {
  width: 50%;
  object-fit: cover;
  border-radius: 8px;
  pointer-events: none;
}

.productText {
  flex-grow: 1;
}

.productTitle {
  font-size: 1.8rem;
  color: #333;
}

.productDescription {
  color: #555;
  margin: 10px 0;
}

.productPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
}

/* Order Form */
.orderForm {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.orderForm h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
}

/* Order Table */
.orderTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.orderTable th,
.orderTable td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.orderTable th {
  background-color: var(--secondaryColor);
  color: white;
}

.orderImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

/* Quantity Input */
.quantityInput {
  width: 50px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Checkout Button */
.checkoutButton {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkoutButton:hover {
  background-color: #218838;
}

/* Order and Cart buttons */
.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.orderButton,
.addToCartButton,
.viewCartButton {
  background: #ff9800;
  margin: 2rem;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.orderButton:hover,
.addToCartButton:hover,
.viewCartButton:hover {
  background: #e68900;
  transform: scale(1.05);
}

.gallery {
  margin-top: 5rem;
 
  padding: 1rem;
  width: 50%;
  margin: 0 auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.productData {
  text-align: center;
  padding: 1rem;
}
