.container {
  background: var(--primaryColor);
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.columnA {
  width: 25%;
  color: #fff;
  padding: 1rem 2rem;
  line-height: 1.5rem;
}

.columnA h4 {
  color: var#fff;
  padding-left: 2rem;
}
.columnA p {
  padding-left: 2rem;
}
.columnB {
  width: 75%;
  padding: 1.5rem;
}
.copyright {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #29343a;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  transition: background 200ms, border 200ms;
}

.card h4 {
  color: var(--secondaryColor);
}
.cardCopyright {
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  transition: background 200ms, border 200ms;
}

.card li {
  color: #fff;
  list-style: none;
  line-height: 2rem;
  cursor: pointer;
}

.copyright li {
  color: #fff;
  font-size: small;
  list-style: none;
  line-height: 2rem;
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}

.copyright p{
  color: #fff;
  font-size: smaller;
}
.card li:hover {
  color: var(--secondaryColor);
}
.copyright li:hover {
  color: var(--secondaryColor);
}
.call {
  display: flex;
  padding-left: 2rem;
}
.imageContainer {
  width: 100%;
  position: unset !important;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  cursor: pointer;
}
.imageContainerCall {
  width: 20%;
  position: unset !important;
  color: #000;
}
.imageCall {
  object-fit: contain;
  width: 60% !important;
  position: relative !important;
  height: unset !important;
  cursor: pointer;
}
.imageContainerProduct {
  width: 100%;
  position: unset !important;
  align-items: center !important;
}
.call h3{
  color: #fff;
}

.columnB h2 {
  font-size: 1rem;
}

.columnB p {
  color: #fff;
  font-size: 0.85rem;
  line-height: 1.5rem;
}
.emailEnquiries p{
  color: #fff;
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .container {
    display: block;
  }
  .columnA {
    width: 100%;
    display: flex;
    padding: 1rem 0.5rem;
  }
  .columnB {
    width: 100%;
    padding: 1rem 4rem;
  }
  .callContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  .call {
    width: 50%;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }
  .emailEnquiries {
    width: 50%;
    display: flex;
  }
  .emailEnquiries p {
    font-size: medium;
  }
  .imageContainer {
    width: 50% !important;
  }
  .imageContainerCall {
    width: 25%;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    display: block;
  }
  .columnA {
    width: 100%;
  }
  .columnB {
    width: 100%;
    padding: 1rem 4rem;
  }
  .imageContainer {
    width: 50% !important;
  }
  .imageContainerCall {
    width: 25%;
  }
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .card {
    margin-bottom: 1rem;
  }
  .columnLeft {
    display: none;
  }
  .card li {
    line-height: 1.5rem;
  }
  .columnRight {
    width: 100%;
  }
  .callContainer {
    display: flex;
    flex-direction: row;
  }
  .call {
    width: 50%;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }
  .emailEnquiries {
    display: flex;
  }
}
