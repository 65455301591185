.container {
  width: 100%;
}
.imageContainer {
  position: fixed;
  top: 45px; /* Adjust based on your nav height */
  left: 0;
  width: 100%;
  transition: top 0.5s ease-in-out;
  z-index: 999;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.hidden {
  top: -400px; /* Moves slider out of view */
}

@media (max-width: 700px) {
}
