/* Payment.module.css */

.paymentContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
  }
  
  select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background: #f8f8f8;
    cursor: pointer;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .payButton {
    width: 100%;
    padding: 12px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .payButton:hover {
    background: #218838;
  }
  
  /* MPESA & Credit Card Specific Styling */
  .mpesaInput {
    border: 2px solid #28a745;
  }
  
  .creditCardInput {
    border: 2px solid #007bff;
  }
  