.productContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .productList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .productCard {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .productCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .productImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .productName {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .productDescription {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .productPrice {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .productButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #db7f34;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    border: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .productButton:hover {
    background-color: var(--secondaryColor);
  }
  
  .buttonLink {
    color: white;
    text-decoration: none;
  }
  
  .productButton:active {
    transform: scale(0.98);
  }
  