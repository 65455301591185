.mainContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.subcontainer {
  background: var(--primaryColor);
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: aliceblue;
}

.leftContainer {
  width: 20%;
  padding-top: 0.5rem;
  padding-left: 5rem;
  text-align: left;
}
.callLeftContainer {
  display: none;
}
.centerContainer {
  width: 60%;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.rightContainer {
  width: 20%;
  padding-right: 1rem;
  text-align: left;
  font-size: 1.1rem;
  color: var(--secondaryColor);
  font-weight: bold;
  display: flex;
  display: row;
}

.imageContainer {
  width: 100%;
  position: unset !important;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  cursor: pointer;
}
.imageContainerWhatsApp {
  width: 10%;
  position: unset !important;
}
.imageWhatsApp {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.formSearch {
  width: 100%;
  display: flex;
  padding: 0.5rem;
}



.inputSearch {
  width: 100%;
  padding: 0.75rem;
  padding-right: 2rem; /* Make space for the icon */
  border-radius: 0.2rem;
  border-color: var(--menusColor);
  border-width: 0.015rem;
  margin-right: 1rem;
}
.inputSearch:focus {
  outline: none !important;
  border-color: var(--secondaryColor);
  box-shadow: 0 0 10px var(--primaryColor);
}

.button {
  width: fit-content;
  border: solid;
  background: var(--primaryColor);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-color: #fff;
  border-width: 0.1rem;
  color: #fff;
  cursor: pointer;
  display: flex;
}



.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 500px;
}



.searchIcon {
  position: absolute;
  right: 1.2rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.searchIcon:hover {
  color: #007bff; /* Change color when hovered */
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .leftContainer {
    padding-left: 0.5rem;
    text-align: left;
  }
  .rightContainer {
    padding-right: 0.5rem;
    text-align: left;
    font-size: 1rem;
  }
  .imageContainerWhatsApp {
    display: none;
  }
}

@media (max-width: 700px) {
  .mainContainer {
    position: fixed;
    z-index: 1;
  }
  .leftContainer {
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
    width: 100%;
    display: flex;
  }
  .leftContainer h4:hover {
    color: var(--secondaryColor);
    cursor: pointer;
  }
  .imageContainer {
    width: 50%;
  }
  .callLeftContainer {
    display: flex;
  }
  .centerContainer {
    display: none;
  }
  .rightContainer {
    display: none;
  }
}
