/* OrderConfirmation.module.css */

.confirmationContainer {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #28a745;
  }
  
  p {
    font-size: 16px;
    margin: 10px 0;
    color: #333;
  }
  
  .orderNumber {
    font-weight: bold;
    font-size: 18px;
    color: #007bff;
  }
  
  .successIcon {
    font-size: 50px;
    color: #28a745;
    margin-bottom: 15px;
  }
  
  .backToHomeButton {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .backToHomeButton:hover {
    background: #0056b3;
  }
  