.container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
}


.columnB {
  width: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
.columnB h2 {
  font-size: large;
  text-align: center;
  color: var(--menuTextColor);
}
.columnB p {
  line-height: 2rem;
  color: var(--textLinks);
}
.imageContainer {
  width: 100%;
  position: unset !important;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.moreDataButton {
  margin-right: 2rem;
  width: "40px";
  height: fit-content;
  padding: 8px;
  border: solid;
  background: var(--primaryColor);
  border-radius: 0.5rem;
  border-color: #fff;
  border-width: 0.1rem;
  color: #fff;
  cursor: pointer;
  float: right;
  align-items: flex-end !important;
  justify-content: right !important;
  margin-bottom: 1rem;
}
.moreDataButton:hover {
  align-items: flex-end;
  background-color: var(--primaryColor);
  transform: 0.75s;
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .columnB h2 {
    font-size: 1rem;
  }

  .columnB p {
    color: #fff;
    font-size: 0.85rem;
    line-height: 2rem;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    
  }
  .columnA {
    display: none;
  }
  .columnB {
    width: 100%;
    padding: 0.5rem 0.5rem;
  }
  .columnB h2 {
    font-size: 1rem;
  }

  .columnB p {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
}
