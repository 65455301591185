/* Container for the entire search results page */
.searchResultsContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
  }
  
  /* Heading for the search results page */
  .searchResultsTitle {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Container for each product */
  .productCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
  }
  
  .productCard:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  /* Product name */
  .productName {
    font-size: 1.8rem;
    color: #2d2d2d;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  /* Product description */
  .productDescription {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Product price */
  .productPrice {
    font-size: 1.2rem;
    color: #e74c3c;
    font-weight: bold;
    margin-top: 10px;
  }
  /* Styling for the Link wrapping each product card */
.productCardLink {
    text-decoration: none; /* Remove the default underline for links */
    color: inherit; /* Inherit text color from parent element */
  }
  
  /* Optional: Add hover effect for the product card link */
  .productCardLink:hover .productCard {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  