.container {
  margin-top: 2rem !important;
  margin-bottom:2rem !important;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
}

.card {
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  border: solid;
  border-radius: 0.5rem;
  border-color: var(--menusColor);
  border-width: 0.1rem;
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.cardTitle {
  background: var(--primaryColor);
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.2rem;
}

.card p {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}
.card:hover {
  cursor: pointer;
  background: var(--hoverColor);
}
.cardTitle:hover {
  background: var(--secondaryColor);
}
.imageContainer {
  width: 100%;
  position: unset !important;
}

.imageFeatured {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.imageContainerProduct {
  width: 100%;
  position: unset !important;
  align-items: center !important;
}
.imageProducts {
  object-fit: contain;
  width: 75% !important;
  position: relative !important;
  height: unset !important;
}
.sectionContainer {
  width: inherit;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2rem;
}
.columnLeft {
  width: 20%;
  border: solid;
  border-radius: 0.5rem;
  border-color: var(--menusColor);
  border-width: 0.1rem;
  height: fit-content;
}
.columnLeft:hover{
  border-color: var(--secondaryColor);
}
.columnRight {
  width: 80%;
}
.columnRight h2 {
  text-align: center;
  color: var(--primaryColor);
}
.placeOrder {
  background: var(--primaryColor);
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.placeOrder p {
  color: azure;
  text-align: center;
}
.placeOrder:hover {
  background: var(--secondaryColor);
}
.columnLeft:hover {
  cursor: pointer;
  background: var(--hoverColor);
}
.featuredTitle {
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
}
.featuredTitle p {
  font-weight: bold;
  color: var(--primaryColor);
  text-align: center;
}
.formSearch {
  width: 100%;
  display: flex;
  padding: 0.5rem;
}
.inputSearch {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.2rem;
  border-color: var(--menusColor);
  border-width: 0.015rem;
}
.inputSearch:focus {
  outline: none !important;
  border-color: var(--secondaryColor);
  box-shadow: 0 0 10px var(--primaryColor);
}

.button {
  width: fit-content;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primaryColor);
}
.button:hover {
  align-items: flex-end;
  background-color: var(--secondaryColor);
  transform: 0.75s;
}
.moreDataButton {
  margin-right: 2rem;
  width: "40px";
  height: fit-content;
  padding: 8px;
  border: solid;
  background: none;
  border-radius: 0.5rem;
  border-color: var(--primaryColor);
  border-width: 0.1rem;
  color: var(--primaryColor);
  cursor: pointer;
  float: right;
  align-items: flex-end !important;
  justify-content: right !important;
}
.moreDataButton:hover {
  align-items: flex-end;
  color: #fff;
  background-color: var(--primaryColor);
  transform: 0.75s;
  border-width: 0rem !important;
}
.textLinks {
  text-align: center;
}
.textLinks li {
  display: inline-block;
  margin-left: 5px;
  font-size: small;
  color: var(--textLinks);
}
.textLinks li:hover {
  color: var(--primaryColor);
  font-weight: bold;
  cursor: pointer;
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .columnLeft {
    width: 40%;
  }
  .columnRight {
    width: 60%;
  }
}
/* Mobile */
@media (max-width: 700px) {
  .container {
    margin-top: 10rem !important;
  }
  .content {
    padding: 4rem;
  }
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .card {
    margin-bottom: 1rem;
  }
  .columnLeft {
    display: none;
  }
  .columnRight {
    width: 100%;
  }
  .card h2 {
    margin-bottom: 0.5rem;
  }
}
