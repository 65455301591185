/* Cart.module.css */

.cartModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cartContainer {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    background: red;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .closeButton:hover {
    background: darkred;
  }
  
 
  .cartTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
  }
  
  .cartTable th, .cartTable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .cartTable th {
    background-color: var(--secondaryColor);
    color: white;
  }
  
  .cartImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }

  .removeButton {
    background: transparent;
    border: none;
    color: red;
    font-size: 20px;
    cursor: pointer;
  }
  
  .removeButton:hover {
    color: darkred;
  }
  
  .checkoutButton {
    background-color: #008cba;
    color: white;
    padding: 15px;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .checkoutButton:hover {
    background-color: #007bb5;
  }
  