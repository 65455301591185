.container {
  width: 100%;
  height: 40px;
  background: var(--menusColor);
  list-style-type: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 0.5rem;
}

.container li {
  padding-left: 40px;
  color: var(--menuTextColor);
  display: inline-block;
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .container{
    height: 50px;
  }
}
/* Mobile */
@media (max-width: 700px) {
  .container{
    height: 50px;
  }
}
