/* UserRegistration.module.css */

.registrationContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .registerButton {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .registerButton:hover {
    background: #0056b3;
  }
  
  h3 {
    font-size: 18px;
    margin: 15px 0;
    color: #444;
  }
  
  /* Google & Facebook Login Buttons */
  .googleLogin, .facebookLogin {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .googleLogin {
    background: #db4437;
    color: white;
  }
  
  .googleLogin:hover {
    background: #b03b2e;
  }
  
  .facebookLogin {
    background: #1877f2;
    color: white;
  }
  
  .facebookLogin:hover {
    background: #155db2;
  }
  