/* Checkout.module.css */
.checkoutContainer {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.itemList {
  list-style: none;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.item div {
  flex: 1;
  text-align: left;
  padding-left: 10px;
}

.item p {
  margin: 5px 0;
  font-size: 14px;
}

h3 {
  font-size: 20px;
  margin-top: 20px;
  color: #555;
}

.payButton, .backButton {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.payButton {
  background: #28a745;
  color: white;
}

.payButton:hover {
  background: #218838;
}

.backButton {
  background: #dc3545;
  color: white;
}

.backButton:hover {
  background: #c82333;
}
